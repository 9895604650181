import React, { useEffect, useState, useCallback } from 'react';
import { FaEdit, FaTrash, FaToggleOn, FaToggleOff } from 'react-icons/fa';
import { getTeams, deleteTeam, toggleTeamStatus } from '@/lib/supabase';
import { Team } from '@/types/Team';
import { Button } from '@/components/ui/button';
import Pagination from './Pagination';

interface TeamListProps {
  onEditTeam: (team: Team) => void;
}

const TeamList: React.FC<TeamListProps> = ({ onEditTeam }) => {
  const [teams, setTeams] = useState<Team[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const fetchTeams = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getTeams();
      setTeams(data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch teams');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    fetchTeams();
  }, [fetchTeams]);

  const handleToggleStatus = async (e: React.MouseEvent, team: Team) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await toggleTeamStatus(team.id, !team.is_active);
      await fetchTeams(); // Refresh the list after toggle
    } catch (err) {
      setError('Failed to update team status');
      console.error(err);
    }
  };

  const handleDelete = async (e: React.MouseEvent, id: string) => {
    e.preventDefault();
    e.stopPropagation();
    if (window.confirm('Are you sure you want to delete this team?')) {
      try {
        await deleteTeam(id);
        await fetchTeams(); // Refresh the list after delete
      } catch (err) {
        setError('Failed to delete team');
        console.error(err);
      }
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-red-500">{error}</div>;

  const totalPages = Math.ceil(teams.length / itemsPerPage);
  const currentTeams = teams.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="bg-zinc-800 rounded-lg overflow-hidden">
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="bg-zinc-700">
            <tr>
              <th className="px-6 py-3 text-left">City</th>
              <th className="px-6 py-3 text-left">State</th>
              <th className="px-6 py-3 text-left">Team Name</th>
              <th className="px-6 py-3 text-left">Status</th>
              <th className="px-6 py-3 text-left">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-zinc-700">
            {currentTeams.map((team) => (
              <tr key={team.id} className="hover:bg-zinc-700">
                <td className="px-6 py-4">{team.city}</td>
                <td className="px-6 py-4">{team.state}</td>
                <td className="px-6 py-4">{team.team_name}</td>
                <td className="px-6 py-4">
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={(e) => handleToggleStatus(e, team)}
                    className={team.is_active ? 'text-green-500' : 'text-zinc-500'}
                  >
                    {team.is_active ? <FaToggleOn size={20} /> : <FaToggleOff size={20} />}
                  </Button>
                </td>
                <td className="px-6 py-4">
                  <div className="flex space-x-2">
                    <Button 
                      variant="ghost"
                      size="icon"
                      onClick={() => onEditTeam(team)}
                      className="text-zinc-400 hover:text-orange-500"
                    >
                      <FaEdit className="h-4 w-4" />
                    </Button>
                    <Button 
                      variant="ghost"
                      size="icon"
                      onClick={(e) => handleDelete(e, team.id)}
                      className="text-zinc-400 hover:text-red-500"
                    >
                      <FaTrash className="h-4 w-4" />
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {teams.length === 0 ? (
        <div className="text-center py-8 text-gray-400">
          No teams found
        </div>
      ) : (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      )}
    </div>
  );
};
export default TeamList;
