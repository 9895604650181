import React, { useState } from 'react';
import { 
  useUsers,
  useUpdateUserRole,
  useUpdateUserEmail,
  useUpdateUserPassword,
  useDeleteUser
} from '@/hooks/api/useUsers';
import { User } from '@/types/Admin';
import { LoadingSkeleton, ErrorState } from './LoadingState';
import Pagination from './Pagination';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { FaEdit, FaTrash } from 'react-icons/fa';

interface ManageFormData {
  email: string;
  password: string;
  role: 'user' | 'admin';
}

const UserList: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const { data, isLoading, error, refetch } = useUsers(currentPage, pageSize);
  const updateRole = useUpdateUserRole();
  const updateEmail = useUpdateUserEmail();
  const updatePassword = useUpdateUserPassword();
  const deleteUserMutation = useDeleteUser();
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [formData, setFormData] = useState<ManageFormData>({
    email: '',
    password: '',
    role: 'user'
  });
  const [activeTab, setActiveTab] = useState<'role' | 'edit' | 'security'>('role');

  const renderManageForm = () => {
    if (!editingUser) return null;

    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
        <div className="bg-zinc-800 p-6 rounded-lg w-full max-w-md">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-xl font-bold">Manage User</h3>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setEditingUser(null)}
            >
              ✕
            </Button>
          </div>

          <div className="mb-6">
            <div className="flex space-x-2 mb-6">
              <Button
                variant={activeTab === 'role' ? 'primary' : 'ghost'}
                onClick={() => setActiveTab('role')}
              >
                Role
              </Button>
              <Button
                variant={activeTab === 'edit' ? 'primary' : 'ghost'}
                onClick={() => setActiveTab('edit')}
              >
                Edit
              </Button>
              <Button
                variant={activeTab === 'security' ? 'primary' : 'ghost'}
                onClick={() => setActiveTab('security')}
              >
                Security
              </Button>
            </div>

            {activeTab === 'role' && (
              <div className="space-y-4">
                <div>
                  <label className="block mb-2">User Role</label>
                  <select
                    value={editingUser.role}
                    onChange={(e) => updateRole.mutate({ 
                      userId: editingUser.id, 
                      role: e.target.value as 'user' | 'admin' 
                    })}
                    className="w-full px-4 py-3 bg-zinc-900 rounded-xl leading-none text-white
                             border border-orange-500/40 focus:border-orange-500/60
                             focus:outline-none focus:ring-2 focus:ring-orange-500/20
                             transition-all duration-300"
                  >
                    <option value="user">User</option>
                    <option value="admin">Admin</option>
                  </select>
                </div>
              </div>
            )}

            {activeTab === 'edit' && (
              <div className="space-y-4">
                <div>
                  <label className="block mb-2">Email Address</label>
                  <div className="flex space-x-2">
                    <Input
                      type="email"
                      value={formData.email}
                      onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      placeholder={editingUser.email}
                    />
                    <Button
                      variant="primary"
                      onClick={() => updateEmail.mutate({ 
                        userId: editingUser.id, 
                        email: formData.email 
                      })}
                      disabled={!formData.email}
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </div>
            )}

            {activeTab === 'security' && (
              <div className="space-y-4">
                <div>
                  <label className="block mb-2">New Password</label>
                  <div className="flex space-x-2">
                    <Input
                      type="password"
                      value={formData.password}
                      onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                      placeholder="New password"
                    />
                    <Button
                      variant="primary"
                      onClick={() => updatePassword.mutate({ 
                        userId: editingUser.id, 
                        password: formData.password 
                      })}
                      disabled={!formData.password}
                    >
                      Update
                    </Button>
                  </div>
                </div>

                <div className="pt-4 border-t border-zinc-700">
                  <Button
                    variant="destructive"
                    onClick={() => {
                      if (window.confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
                        deleteUserMutation.mutate(editingUser.id);
                        setEditingUser(null);
                      }
                    }}
                    className="w-full"
                  >
                    <FaTrash className="mr-2" /> Delete Account
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) return <LoadingSkeleton />;
  if (error) return <ErrorState message="Failed to load users" onRetry={() => refetch()} />;

  return (
    <div className="bg-zinc-800 rounded-lg overflow-hidden">
      {renderManageForm()}
      <div className="p-4">
        <h2 className="text-xl font-bold mb-4">Users</h2>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="bg-zinc-700">
            <tr>
              <th className="px-6 py-3 text-left">Email</th>
              <th className="px-6 py-3 text-left">Role</th>
              <th className="px-6 py-3 text-left">Created At</th>
              <th className="px-6 py-3 text-left">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-zinc-700">
            {data?.users.map((user) => (
              <tr key={user.id} className="hover:bg-zinc-700">
                <td className="px-6 py-4">{user.email}</td>
                <td className="px-6 py-4">
                  <span className={`px-3 py-1.5 rounded-full text-sm font-medium ${
                    user.role === 'admin' 
                      ? 'bg-orange-500/20 text-orange-500 border border-orange-500/30' 
                      : 'bg-zinc-600/20 text-zinc-400 border border-zinc-600/30'
                  }`}>
                    {user.role}
                  </span>
                </td>
                <td className="px-6 py-4">
                  {new Date(user.created_at).toLocaleDateString()}
                </td>
                <td className="px-6 py-4">
                  <Button 
                    variant="primary"
                    size="sm"
                    onClick={() => setEditingUser(user)}
                    className="flex items-center gap-2"
                  >
                    <FaEdit className="h-4 w-4" />
                    Manage
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {data?.users.length === 0 ? (
        <div className="text-center py-8 text-gray-400">
          No users found
        </div>
      ) : (
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil((data?.total || 0) / pageSize)}
          onPageChange={setCurrentPage}
        />
      )}
    </div>
  );
};

export default UserList;
