import React, { useState, useEffect } from 'react';
import { createProduct } from '../../../lib/supabase';
import { Product } from '../../../types/Admin';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';

interface ProductFormProps {
  editingProduct: Product | null;
  onCancel: () => void;
}

interface ProductFormData {
  name: string;
  price: string;
  stock: string;
  category: string;
  description: string;
}
const ProductForm: React.FC<ProductFormProps> = ({ editingProduct, onCancel }) => {
  const [formData, setFormData] = useState<ProductFormData>({
    name: '',
    price: '',
    stock: '',
    category: '',
    description: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (editingProduct) {
      setFormData({
        name: editingProduct.name,
        price: editingProduct.price.toString(),
        stock: editingProduct.stock.toString(),
        category: editingProduct.category,
        description: editingProduct.description || ''
      });
    }
  }, [editingProduct]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const productData = {
        name: formData.name,
        price: parseFloat(formData.price),
        stock: parseInt(formData.stock),
        category: formData.category,
        description: formData.description
      };

      await createProduct(productData);
      setFormData({
        name: '',
        price: '',
        stock: '',
        category: '',
        description: ''
      });
    } catch (err) {
      setError('Failed to create product');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="bg-zinc-800 p-6 rounded-lg mt-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">
          {editingProduct ? 'Edit Product' : 'Add New Product'}
        </h2>
        {editingProduct && (
          <Button
            onClick={onCancel}
            variant="ghost"
            size="sm"
          >
            Cancel
          </Button>
        )}
      </div>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-2">Name</label>
          <Input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block mb-2">Price</label>
            <Input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleChange}
            />
          </div>
          <div>
            <label className="block mb-2">Stock</label>
            <Input
              type="number"
              name="stock"
              value={formData.stock}
              onChange={handleChange}
            />
          </div>
        </div>

        <div>
          <label className="block mb-2">Category</label>
          <select
            name="category"
            value={formData.category}
            onChange={handleChange}
            className="w-full px-4 py-3 bg-zinc-900 rounded-xl leading-none text-white text-lg
                     border border-orange-500/40 focus:border-orange-500/60
                     focus:outline-none focus:ring-2 focus:ring-orange-500/20
                     transition-all duration-300"
          >
            <option value="">Select Category</option>
            <option value="category-a">Category A</option>
            <option value="category-b">Category B</option>
          </select>
        </div>

        <div>
          <label className="block mb-2">Description</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            rows={4}
            className="w-full px-4 py-3 bg-zinc-900 rounded-xl leading-none text-white text-lg
                     border border-orange-500/40 focus:border-orange-500/60
                     focus:outline-none focus:ring-2 focus:ring-orange-500/20
                     transition-all duration-300"
          />
        </div>

        <Button
          type="submit"
          variant="primary"
          disabled={loading}
        >
          {loading ? 'Saving...' : 'Save Product'}
        </Button>
      </form>
    </div>
  );
};
export default ProductForm;
