// src/components/admin/components/Dashboard.tsx
import React, { useEffect, useState } from 'react';
import { FaUsers, FaShoppingCart, FaBox, FaDollarSign } from 'react-icons/fa';
import { getDashboardStats } from '../../../lib/supabase';

const StatCard = ({ title, value, icon: Icon, color }: { title: string, value: string, icon: any, color: string }) => (
  <div className={`p-6 bg-zinc-800 rounded-lg shadow-lg`}>
    <div className="flex items-center">
      <div className={`p-3 rounded-full ${color} mr-4`}>
        <Icon className="text-white text-2xl" />
      </div>
      <div>
        <p className="text-gray-400 text-sm">{title}</p>
        <p className="text-2xl font-bold text-white">{value}</p>
      </div>
    </div>
  </div>
);

const Dashboard: React.FC = () => {
  const [stats, setStats] = useState({
    totalUsers: 0,
    totalOrders: 0,
    totalProducts: 0,
    totalRevenue: 0
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const data = await getDashboardStats();
        setStats(data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  if (loading) return <div>Loading...</div>;

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <StatCard 
          title="Total Users" 
          value={stats.totalUsers.toString()} 
          icon={FaUsers} 
          color="bg-blue-500"
        />
        <StatCard 
          title="Total Orders" 
          value={stats.totalOrders.toString()} 
          icon={FaShoppingCart} 
          color="bg-green-500"
        />
        <StatCard 
          title="Products" 
          value={stats.totalProducts.toString()} 
          icon={FaBox} 
          color="bg-purple-500"
        />
        <StatCard 
          title="Revenue" 
          value={`$${stats.totalRevenue.toFixed(2)}`} 
          icon={FaDollarSign} 
          color="bg-orange-500"
        />
      </div>
      
      <div className="bg-zinc-800 p-6 rounded-lg">
        <h3 className="text-xl font-bold mb-4">Recent Activity</h3>
        {/* Add chart or activity feed here */}
      </div>
    </div>
  );
};
export default Dashboard;
