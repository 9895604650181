import React from 'react';
import { Button } from '@/components/ui/button';

const OrderList: React.FC = () => {
  return (
    <div className="bg-zinc-800 rounded-lg overflow-hidden">
      <div className="p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Orders</h2>
          <Button variant="primary">New Order</Button>
        </div>
      </div>
      <div className="text-center py-8 text-gray-400">
        No orders found
      </div>
    </div>
  );
};

export default OrderList;
