import React, { useState, useCallback } from 'react';
import TeamList from '../components/TeamList';
import TeamForm from '../components/TeamForm';
import { Team } from '@/types/Team';

const AdminTeams: React.FC = () => {
  const [editingTeam, setEditingTeam] = useState<Team | null>(null);
  const [listKey, setListKey] = useState(0); // Add this to force refresh
  const handleCancel = () => {
    setEditingTeam(null);
  };

  const handleSave = useCallback(() => {
    setListKey(prev => prev + 1); // Force TeamList to remount
    setEditingTeam(null);
  }, []);

  const handleEditTeam = useCallback((team: Team) => {
    setEditingTeam(team);
  }, []);
  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Manage Teams</h1>
      <TeamList 
        key={listKey}
        onEditTeam={handleEditTeam}
      />
      <TeamForm 
        editingTeam={editingTeam} 
        onCancel={handleCancel}
        onSave={handleSave}
      />
    </div>
  );
};
export default AdminTeams;
