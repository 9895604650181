// src/hooks/api/useUsers.ts
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { User } from '@/types/Admin';
import { supabaseAdmin } from '@/lib/supabase';
import { 
  updateUserRole, 
  updateUserEmail, 
  updateUserPassword,
  deleteUser
} from '@/lib/supabase';

interface UsersResponse {
  users: User[];
  total: number;
}

export const useUsers = (page: number, pageSize: number) => {
  return useQuery<UsersResponse>({
    queryKey: ['users', page, pageSize],
    queryFn: async () => {
      try {
        const start = (page - 1) * pageSize;
        const end = start + pageSize - 1;

        // First get profiles with pagination
        const { data: profiles, error: profileError, count } = await supabaseAdmin
          .from('profiles')
          .select('*', { count: 'exact' })
          .range(start, end)
          .order('created_at', { ascending: false });

        if (profileError) throw profileError;

        // Then get auth users
        const { data: { users: authUsers }, error: authError } = await supabaseAdmin
          .auth.admin.listUsers();

        if (authError) throw authError;

        if (!profiles) return { users: [], total: 0 };

        // Combine the data
        const users = profiles.map(profile => {
          const authUser = authUsers.find(au => au.id === profile.id);
          return {
            id: profile.id,
            email: authUser?.email || '',
            role: profile.role as 'admin' | 'user',
            is_suspended: profile.is_suspended || false,
            created_at: profile.created_at
          };
        });

        return {
          users,
          total: count || 0
        };
      } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
      }
    }
  });
};

export const useUpdateUserRole = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ userId, role }: { userId: string; role: 'admin' | 'user' }) =>
      updateUserRole(userId, role),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });
};

export const useUpdateUserEmail = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ userId, email }: { userId: string; email: string }) =>
      updateUserEmail(userId, email),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });
};

export const useUpdateUserPassword = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ userId, password }: { userId: string; password: string }) =>
      updateUserPassword(userId, password),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (userId: string) => deleteUser(userId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
  });
};