import React from 'react';

export const LoadingSkeleton: React.FC = () => {
  return (
    <div className="bg-zinc-800 rounded-lg p-6 space-y-4">
      <div className="animate-pulse space-y-6">
        <div className="h-6 bg-zinc-700 rounded w-1/4"></div>
        <div className="space-y-3">
          {[...Array(5)].map((_, i) => (
            <div key={i} className="grid grid-cols-4 gap-4">
              <div className="h-4 bg-zinc-700 rounded col-span-1"></div>
              <div className="h-4 bg-zinc-700 rounded col-span-1"></div>
              <div className="h-4 bg-zinc-700 rounded col-span-1"></div>
              <div className="h-4 bg-zinc-700 rounded col-span-1"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const ErrorState: React.FC<{ message: string; onRetry?: () => void }> = ({
  message,
  onRetry
}) => {
  return (
    <div className="bg-zinc-800 rounded-lg p-6">
      <div className="text-center">
        <div className="text-red-500 mb-4">⚠️ {message}</div>
        {onRetry && (
          <button
            onClick={onRetry}
            className="px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 
                     transition-colors duration-200"
          >
            Try Again
          </button>
        )}
      </div>
    </div>
  );
};
