import React from 'react';
import UserList from '../components/UserList';

const AdminUsers: React.FC = () => {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Users</h1>
      <UserList />
    </div>
  );
};

export default AdminUsers;
