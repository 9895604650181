// src/lib/api/utils/errorHandler.ts
import { PostgrestError } from '@supabase/supabase-js';
import { EnhancedAPIError } from '@/types/ApiTypes';

export function isPostgrestError(error: unknown): error is PostgrestError {
  return (
    typeof error === 'object' &&
    error !== null &&
    'code' in error &&
    'message' in error &&
    'details' in error
  );
}

export function handleSupabaseError(error: PostgrestError | Error | unknown, defaultMessage: string): never {
  if (isPostgrestError(error)) {
    throw new EnhancedAPIError(
      `${defaultMessage}: ${error.message}`,
      error.code,
      Number(error.code) || 500,
      error.details
    );
  }
  
  if (error instanceof Error) {
    throw new EnhancedAPIError(
      `${defaultMessage}: ${error.message}`,
      'UNKNOWN_ERROR',
      500
    );
  }

  throw new EnhancedAPIError(
    defaultMessage,
    'UNKNOWN_ERROR',
    500
  );
}
