// src/components/admin/pages/AdminProducts.tsx
import React, { useState } from 'react';
import ProductList from '../components/ProductList';
import ProductForm from '../components/ProductForm';
import { Product } from '../../../types/Admin';

const AdminProducts: React.FC = () => {
  const [editingProduct, setEditingProduct] = useState<Product | null>(null);
  
  const handleCancel = () => {
    setEditingProduct(null);
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Manage Products</h1>
      <ProductList onEditProduct={setEditingProduct} />
      {/* Always show the form, it handles both create and edit */}
      <ProductForm 
        editingProduct={editingProduct} 
        onCancel={handleCancel} 
      />
    </div>
  );
};
export default AdminProducts;
