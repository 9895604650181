import { createClient } from '@supabase/supabase-js';
import type { User } from '@/types/Admin';
import type { Product } from '@/types/Store';
import type { Team } from '@/types/Team';
import { handleSupabaseError } from '@/lib/api/utils/errorHandler';

// Client initialization
const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;
const supabaseServiceRole = import.meta.env.VITE_SUPABASE_SERVICE_ROLE_KEY;

export const supabase = createClient(supabaseUrl, supabaseAnonKey);
export const supabaseAdmin = createClient(supabaseUrl, supabaseServiceRole);

// User management functions
export async function getUsers(page: number, limit: number) {
  try {
    const { data: profilesData, error: profilesError, count } = await supabaseAdmin
      .from('profiles')
      .select('id, role, created_at, is_suspended', { count: 'exact' })
      .range((page - 1) * limit, page * limit - 1);

    if (profilesError) {
      throw handleSupabaseError(profilesError, 'Failed to fetch users');
    }

    const { data: authData, error: authError } = await supabaseAdmin
      .auth.admin.listUsers();

    if (authError) {
      throw handleSupabaseError(authError, 'Failed to fetch auth users');
    }

    const users: User[] = (profilesData || []).map(profile => {
      const authUser = authData.users.find(au => au.id === profile.id);
      return {
        id: profile.id,
        email: authUser?.email || '',
        created_at: profile.created_at,
        role: profile.role as 'admin' | 'user',
        is_suspended: profile.is_suspended || false
      };
    });

    return {
      users,
      total: count || 0
    };
  } catch (error) {
    throw handleSupabaseError(error, 'Failed to fetch users');
  }
}

export async function updateUserRole(userId: string, role: 'admin' | 'user') {
  const { error } = await supabaseAdmin
    .from('profiles')
    .update({ role })
    .eq('id', userId);

  if (error) throw handleSupabaseError(error, 'Failed to update user role');
}

export async function updateUserEmail(userId: string, email: string) {
  const { error } = await supabaseAdmin
    .auth.admin.updateUserById(userId, { email });
  if (error) throw handleSupabaseError(error, 'Failed to update user email');
}

export async function updateUserPassword(userId: string, password: string) {
  const { error } = await supabaseAdmin
    .auth.admin.updateUserById(userId, { password });
  if (error) throw handleSupabaseError(error, 'Failed to update user password');
}

export async function deleteUser(userId: string) {
  const { error } = await supabaseAdmin
    .auth.admin.deleteUser(userId);
  if (error) throw handleSupabaseError(error, 'Failed to delete user');
}

// Dashboard functions
export async function getDashboardStats() {
  try {
    const { data: products, error: productsError } = await supabaseAdmin
      .from('products')
      .select('count', { count: 'exact' });

    const { data: orders, error: ordersError } = await supabaseAdmin
      .from('orders')
      .select('count, total_amount', { count: 'exact' });

    const { data: users, error: usersError } = await supabaseAdmin
      .from('users')
      .select('count', { count: 'exact' });

    if (productsError || ordersError || usersError) {
      throw handleSupabaseError(
        productsError || ordersError || usersError,
        'Error fetching dashboard stats'
      );
    }

    const totalRevenue = orders?.reduce((sum, order) => sum + (order.total_amount || 0), 0) || 0;

    return {
      totalProducts: products?.[0]?.count || 0,
      totalOrders: orders?.[0]?.count || 0,
      totalUsers: users?.[0]?.count || 0,
      totalRevenue
    };
  } catch (error) {
    throw handleSupabaseError(error, 'Failed to fetch dashboard stats');
  }
}

// Product functions
export async function getProducts() {
  const { data, error } = await supabaseAdmin
    .from('products')
    .select('*')
    .order('created_at', { ascending: false });
    
  if (error) throw handleSupabaseError(error, 'Failed to fetch products');
  return data;
}

export async function createProduct(product: Omit<Product, 'id' | 'created_at' | 'updated_at'>) {
  const { data, error } = await supabaseAdmin
    .from('products')
    .insert(product)
    .select()
    .single();
    
  if (error) throw handleSupabaseError(error, 'Failed to create product');
  return data;
}

export async function deleteProduct(id: string) {
  const { error } = await supabaseAdmin
    .from('products')
    .delete()
    .eq('id', id);
    
  if (error) throw handleSupabaseError(error, 'Failed to delete product');
}

// Team functions
export async function getTeams() {
  const { data, error } = await supabase
    .from('teams')
    .select('*')
    .order('city');
    
  if (error) throw handleSupabaseError(error, 'Failed to fetch teams');
  return data;
}

export async function createTeam(team: Omit<Team, 'id' | 'created_at' | 'updated_at'>) {
  const { data, error } = await supabaseAdmin
    .from('teams')
    .insert(team)
    .select()
    .single();
    
  if (error) throw handleSupabaseError(error, 'Failed to create team');
  return data;
}

export async function updateTeam(id: string, updates: Partial<Team>) {
  const { data, error } = await supabaseAdmin
    .from('teams')
    .update(updates)
    .eq('id', id)
    .select()
    .single();
    
  if (error) throw handleSupabaseError(error, 'Failed to update team');
  return data;
}

export async function toggleTeamStatus(id: string, is_active: boolean) {
  const { data, error } = await supabaseAdmin
    .from('teams')
    .update({ is_active })
    .eq('id', id)
    .select()
    .single();
    
  if (error) throw handleSupabaseError(error, 'Failed to update team status');
  return data;
}

export async function deleteTeam(id: string) {
  const { error } = await supabaseAdmin
    .from('teams')
    .delete()
    .eq('id', id);
    
  if (error) throw handleSupabaseError(error, 'Failed to delete team');
}
