import React, { useState, useEffect } from 'react';
import { createTeam, updateTeam } from '@/lib/supabase';
import { Team } from '@/types/Team';
import { Button } from '@/components/ui/button';
import { Input } from "@/components/ui/input";

interface TeamFormProps {
  editingTeam: Team | null;
  onCancel: () => void;
  onSave: () => void;
}

interface TeamFormData {
  city: string;
  state: string;
  team_name: string;
  zip_code: string;
  is_active: boolean;
}

const TeamForm: React.FC<TeamFormProps> = ({ editingTeam, onCancel, onSave }) => {
  const [formData, setFormData] = useState<TeamFormData>({
    city: '',
    state: '',
    team_name: '',
    zip_code: '',
    is_active: true
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (editingTeam) {
      setFormData({
        city: editingTeam.city,
        state: editingTeam.state,
        team_name: editingTeam.team_name,
        zip_code: editingTeam.zip_code,
        is_active: editingTeam.is_active ?? true
      });
    }
  }, [editingTeam]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      if (editingTeam) {
        await updateTeam(editingTeam.id, formData);
      } else {
        await createTeam(formData);
      }
      setFormData({
        city: '',
        state: '',
        team_name: '',
        zip_code: '',
        is_active: true
      });
      onSave();  // Added this line
    } catch (err) {
      setError('Failed to save team');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-zinc-800 p-6 rounded-lg mt-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">
          {editingTeam ? 'Edit Team' : 'Add New Team'}
        </h2>
        {editingTeam && (
          <Button
            onClick={onCancel}
            variant="ghost"
            size="sm"
          >
            Cancel
          </Button>
        )}
      </div>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-2">City</label>
          <Input
            type="text"
            value={formData.city}
            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
            required
          />
        </div>

        <div>
          <label className="block mb-2">State</label>
          <Input
            type="text"
            value={formData.state}
            onChange={(e) => setFormData({ ...formData, state: e.target.value })}
            maxLength={2}
            required
          />
        </div>

        <div>
          <label className="block mb-2">Team Name</label>
          <Input
            type="text"
            value={formData.team_name}
            onChange={(e) => setFormData({ ...formData, team_name: e.target.value })}
            required
          />
        </div>

        <div>
          <label className="block mb-2">ZIP Code</label>
          <Input
            type="text"
            value={formData.zip_code}
            onChange={(e) => setFormData({ ...formData, zip_code: e.target.value })}
            required
            pattern="[0-9]{5}"
            title="Five digit ZIP code"
          />
        </div>

        <div className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={formData.is_active}
            onChange={(e) => setFormData({ ...formData, is_active: e.target.checked })}
            className="rounded border-zinc-600 bg-zinc-700 text-orange-500 focus:ring-orange-500/40 focus:ring-offset-zinc-800"
          />
          <label>Active</label>
        </div>

        <Button
          type="submit"
          variant="primary"
          disabled={loading}
        >
          {loading ? 'Saving...' : 'Save Team'}
        </Button>
      </form>
    </div>
  );
};

export default TeamForm;

